import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogout } from './utils/logout.js';
import { auth, db } from './firebaseConfig.js';
import { collection, addDoc, query, doc,  getDocs, orderBy, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import './css/QuestionsPage.css';
import { GAD7_QUESTIONS, PHQ9_QUESTIONS, GAD2_QUESTIONS, PHQ2_QUESTIONS, ANSWER_OPTIONS, questionList1, questionList2, YES_NO_OPTIONS } from './utils/questions.js';
import { getPublicKey } from '@stellar/freighter-api';
import JoiAppLogo from './joiapplogo.png'; 

const QuestionsPage = () => {
    const navigate = useNavigate();
    const logout = useLogout();
    const userPublicKey = localStorage.getItem('userPublicKey');
    const [answers, setAnswers] = useState({});
    const [currentQuestionSet, setCurrentQuestionSet] = useState('PHQ2_GAD2_QuestionList1');
    const [isCameraEnabled, setIsCameraEnabled] = useState(false); // State to track if camera is enabled
    const [isRecordingCompleted, setIsRecordingCompleted] = useState(false); // State to track if recording is completed
    const [isWaitingForResults, setIsWaitingForResults] = useState(false); // State to track if waiting for server results
    const [results, setResults] = useState({
        dominant_emotions: [],
        results: []
    });
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const recordedChunksRef = useRef([]);
    const [recordingLog, setRecordingLog] = useState([]);
    const [isPHQ9Completed, setIsPHQ9Completed] = useState(false);

    const startRecording = () => {
        if (!videoRef.current || !videoRef.current.srcObject) {
            alert("Please enable camera and microphone first.");
            return;
        }
        if (videoRef.current && videoRef.current.srcObject) {
            const recorder = new MediaRecorder(videoRef.current.srcObject, { mimeType: 'video/webm' });

            recorder.ondataavailable = event => {
                if (event.data && event.data.size > 0) {
                    recordedChunksRef.current.push(event.data);
                    addLog('Data available: ' + event.data.size + ' bytes');
                } else {
                    addLog('No data available or data size is 0');
                }
            };

            recorder.onstop = async () => {
                addLog('Recording stopped.');
                setIsRecordingCompleted(true);

                if (!auth.currentUser) {
                    addLog('User is not authenticated. Cannot upload video.');
                    return;
                }

              //  const userId = auth.currentUser.uid;
              const userId = userPublicKey;
                addLog(`questionsPage: auth.currentUser.uid: ${userId}`);

                const videoBlob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
                recordedChunksRef.current = [];

                if (videoBlob.size === 0) {
                    addLog('Video too short or empty. Please record a longer video.');
                    return;
                }

                const formData = new FormData();
                formData.append('video', videoBlob, 'recordedVideo.webm');
                formData.append('userId', userId);

                try {
                    setIsWaitingForResults(true); // Show waiting indicator
                    const API_URL = "https://joiapp.org";
                    const response = await fetch('http://127.0.0.1:8080/analyze', {
                        method: 'POST',
                        body: formData,
                        headers: { 'Accept': 'application/json' },
                    });

                    if (response.ok) {
            //            const result = await response.json();
                        const responseText = await response.text();
                        console.log(responseText); 
                        const result = JSON.parse(responseText); // Parse
                        const flattenedResults = result[0];
                        setResults(flattenedResults); // Store the results
                        addLog("Video uploaded successfully. Dominant emotions: " + result.dominant_emotions.join(", "));
                    } else {
                        addLog("Upload failed: " + await response.text());
                    }
                } catch (error) {
                    addLog("Error uploading video: " + error.message);
                } finally {
                    setIsWaitingForResults(false); // Hide waiting indicator
                }
            };

            recorder.start();
            mediaRecorderRef.current = recorder;
            addLog("Recording started.");
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        disableMedia();
    };

    const handleMediaPermissions = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                setIsCameraEnabled(true); // Set camera enabled state
                startRecording();
                addLog("Camera and microphone enabled.");
            }
        } catch (error) {
            alert("Unable to access camera and microphone. Please check permissions.");
        }
    };

    const disableMedia = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            videoRef.current.srcObject = null;
            setIsCameraEnabled(false); // Set camera enabled state
            addLog("Camera and microphone disabled.");
        }
    };

    const handleAnswerChange = (question, value) => {
        if (!isCameraEnabled) {
            alert("Please enable the camera and microphone first.");
            handleMediaPermissions(); // Prompt to enable camera
            startRecording();
            return;
        }
        setAnswers(prevAnswers => ({ ...prevAnswers, [question]: value }));
    };

    const validateAnswers = () => {
        const requiredQuestions = currentQuestionSet === 'PHQ2_GAD2_QuestionList1'
            ? [...PHQ2_QUESTIONS, ...GAD2_QUESTIONS, ...questionList1]
            : [...PHQ9_QUESTIONS, ...GAD7_QUESTIONS, ...questionList2];

        for (let questionObj of requiredQuestions) {
            if (!answers.hasOwnProperty(questionObj.question)) {
                return false;
            }
        }
        return true;
    };

    const saveAnswersToFirestore = async () => {
        if (!validateAnswers()) {
            alert("Please answer all the questions before submitting.");
            return;
        }

        stopRecording();

        if (!auth.currentUser) {
            alert("User is not authenticated");
            return;
        }

        try {
            const userAnswers = Object.values(answers);
            let phq9Total = 0;
            let gad7Total = 0;
            let phq2Total = 0;
            let gad2Total = 0;

            let gad2Answers = {};
            let phq2Answers = {};
            let gad7Answers = {};
            let phq9Answers = {};

            console.log("in saveAnswersToFirestore isPHQ9Completed: ", isPHQ9Completed);
            console.log("in saveAnswersToFirestore currentQuestionSet: ", currentQuestionSet);

            if (currentQuestionSet === 'PHQ9_GAD7_QuestionList2') {
                for (let [question, answer] of Object.entries(answers)) {
                    console.log("in for loop of 111111 question: ", question);
                    const questionObject = PHQ9_QUESTIONS.find(q => q.question === question) 
                    || GAD7_QUESTIONS.find(q => q.question === question) || questionList2.find(q => q.question === question)  ;

                    if (questionObject.heading === 'PHQ-9') {
                        phq9Total += parseInt(answer);
                        phq9Answers[question] = parseInt(answer);
                    } else if (questionObject.heading === 'GAD-7') {
                        gad7Total += parseInt(answer);
                        gad7Answers[question] = parseInt(answer);
                    }
                    else 
                        ;
                }
            } else {
                for (let [question, answer] of Object.entries(answers)) {
                    const questionObject = PHQ2_QUESTIONS.find(q => q.question === question) || GAD2_QUESTIONS.find(q => q.question === question)
                    ||  questionList1.find(q => q.question === question) ;
                    if (questionObject.heading === 'PHQ-2') {
                        phq2Total += parseInt(answer);
                        phq2Answers[question] = parseInt(answer);
                    } else if (questionObject.heading === 'GAD-2') {
                        gad2Total += parseInt(answer);
                        gad2Answers[question] = parseInt(answer);
                    }
                    else ;
                }
            }
            
            await addDoc(collection(db, 'users', userPublicKey, 'answers'), {
                answers: userAnswers,
                phq9_total_score: phq9Total,
                gad7_total_score: gad7Total,
                phq2_total_score: phq2Total,
                gad2_total_score: gad2Total,
                timestamp: new Date()
            });

            if (Object.keys(gad7Answers).length > 0) {
                
                await addDoc(collection(db, 'users', userPublicKey, 'gad7'), {
                    answers: gad7Answers,
                    gad7_total_score: gad7Total,
                    timestamp: new Date()
                });
            }

            if (Object.keys(phq9Answers).length > 0) {
                await addDoc(collection(db, 'users', userPublicKey, 'phq9'), {
                    answers: phq9Answers,
                    phq9_total_score: phq9Total,
                    timestamp: new Date()
                });
                setIsPHQ9Completed(true);
            }

            if (Object.keys(gad2Answers).length > 0) {
                await addDoc(collection(db, 'users', userPublicKey, 'gad2'), {
                    answers: gad2Answers,
                    gad2_total_score: gad2Total,
                    timestamp: new Date()
                });
            }

            if (Object.keys(phq2Answers).length > 0) {
                await addDoc(collection(db, 'users', userPublicKey, 'phq2'), {
                    answers: phq2Answers,
                    phq2_total_score: phq2Total,
                    timestamp: new Date()
                });
            }

            if (isPHQ9Completed) {
                await setDoc(doc(db, 'users', userPublicKey, 'completedSets', 'PHQ9'), {
                    completed: true,
                    timestamp: new Date()
                });
            }

            alert("Answers saved successfully");
            navigate('/results');
        } catch (error) {
            alert("Failed to save answers. Please try again.");
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                navigate('/');
            } else {
                const phq9DocRef = collection(db, 'users', userPublicKey, 'phq9');
                const phq9Query = query(phq9DocRef, orderBy('timestamp', 'desc'));
                const phq9Snapshot = await getDocs(phq9Query);

                if (!phq9Snapshot.empty) {
                    const lastPHQ9 = phq9Snapshot.docs[0].data();
                    const phq9Questions = PHQ9_QUESTIONS.map(q => q.question);
                    const answeredQuestions = Object.keys(lastPHQ9.answers);
                    const hasCompletedPHQ9 = phq9Questions.every(q => answeredQuestions.includes(q));
                    setIsPHQ9Completed(hasCompletedPHQ9);
                    if (hasCompletedPHQ9) {
                        setCurrentQuestionSet('PHQ2_GAD2_QuestionList1');
                    } else {
                        setCurrentQuestionSet('PHQ9_GAD7_QuestionList2');
                    }
                } else {
                    setCurrentQuestionSet('PHQ9_GAD7_QuestionList2');
                }
            }
        });

        return () => {
            unsubscribe();
            disableMedia();
        };
    }, [navigate]);

    const renderQuestions = (questions) => {
        return questions.map((item, index) => (
            <div key={index} className="question-container">
                <p>{item.question}</p>
                {item.answerType === 'radio' ? (
                    ANSWER_OPTIONS.map(option => (
                        <label key={option.value}>
                            <input
                                type="radio"
                                name={`${item.question}-${index}`}
                                value={option.value}
                                checked={answers[item.question] === option.value}
                                onChange={() => handleAnswerChange(item.question, option.value)}
                            />
                            {option.label}
                        </label>
                    ))
                ) : item.answerType === 'yesNo' ? (
                    YES_NO_OPTIONS.map(option => (
                        <label key={option.value}>
                            <input
                                type="radio"
                                name={`${item.question}-${index}`}
                                value={option.value}
                                checked={answers[item.question] === option.value}
                                onChange={() => handleAnswerChange(item.question, option.value)}
                            />
                            {option.label}
                        </label>
                    ))
                ) : (
                    <input
                        type="text"
                        value={answers[item.question] || ''}
                        onChange={(e) => handleAnswerChange(item.question, e.target.value)}
                    />
                )}
            </div>
        ));
    };

    const addLog = (message) => {
        setRecordingLog(prevLog => [...prevLog, message]);
    };

    const handleRecordingCompleted = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecordingCompleted(true);
            setIsWaitingForResults(true);  // Set waiting for results to true
        }
        disableMedia();
    };

    return (
        <div className="questions-page">
            <div className="header">
                <div className="logo-container">
                    <img src={JoiAppLogo} alt="JoiApp Logo" className="logo" />
                    <span className="app-name">JoiApp</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {userPublicKey && <p>Public Key: {userPublicKey}</p>}
                    </div>
                <button onClick={logout} className="logout-button">
                    Logout
                </button>
            </div>


            <div className="container">
                
            <p style={{ fontSize: '24px' }}>Welcome to the questions page.</p>

                
                <div className="button-group">
         
                    <button onClick={handleMediaPermissions}>Enable Camera and Microphone</button>
                    <button onClick={disableMedia}>Disable Camera and Microphone</button>
                    <button onClick={startRecording}>Start Recording</button>
                    <button onClick={stopRecording}>Stop Recording & Upload</button>

                </div>
    
                <div className="video-container">
                    <video ref={videoRef} style={{ width: '100%', maxHeight: '400px', border: '2px solid #ccc' }} autoPlay playsInline></video>
                </div>
    
                <div className="log-container">
                    <h2>Recording Log</h2>
                    <ul>
                        {recordingLog.map((log, index) => (
                            <li key={index}>{log}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="form-container">
            <p> Please answer the following questions:</p>
                {currentQuestionSet === 'PHQ2_GAD2_QuestionList1' ? (
                    <>
                        <h2>PHQ-2 Questions</h2>
                        {renderQuestions(PHQ2_QUESTIONS.map(q => ({ question: q.question, answerType: 'radio' })))}
    
                        <h2>GAD-2 Questions</h2>
                        {renderQuestions(GAD2_QUESTIONS.map(q => ({ question: q.question, answerType: 'radio' })))}
    
                        <h2>Additional Questions</h2>
                        {renderQuestions(questionList1.map(q => q.answerType === 'yesNo' ? { question: q.question, answerType: 'yesNo' } : { question: q.question, answerType: 'text' }))}
                    </>
                ) : currentQuestionSet === 'PHQ9_GAD7_QuestionList2' && (
                    <>
                        <h2>PHQ-9 Questions</h2>
                        {renderQuestions(PHQ9_QUESTIONS.map(q => ({ question: q.question, answerType: 'radio', heading: 'PHQ-9' })))}
    
                        <h2>GAD-7 Questions</h2>
                        {renderQuestions(GAD7_QUESTIONS.map(q => ({ question: q.question, answerType: 'radio', heading: 'GAD-7' })))}
    
                        <h2>Additional Questions</h2>
                        {renderQuestions(questionList2.map(q => q.answerType === 'yesNo' ? { question: q.question, answerType: 'yesNo' } : { question: q.question, answerType: 'text' }))}
                    </>
                )}
    
    <div className="submit-container">
    <button onClick={handleRecordingCompleted} className="submit-button">
        Completed
    </button>
    {isWaitingForResults && <p className="waiting-text">Waiting for results...</p>}
            <button
            onClick={saveAnswersToFirestore}
            className="submit-button"
            disabled={!isRecordingCompleted || isWaitingForResults} // Disable until recording is completed and not waiting for results
        >
            Submit Answers
        </button>

</div>

                {results && (
    <div className="results-log">
        <h2>Results</h2>
        <div>


            <h3>Analysis Results</h3>
            <ul>
                {Array.isArray(results.results) && results.results.length > 0 ? (
                    results.results.map((analysis, index) => (
                        <li key={index}>
                            <pre>{JSON.stringify(analysis, null, 2)}</pre>
                        </li>
                    ))
                ) : (
                    <li>No analysis results available</li>
                )}
            </ul>
        </div>
    </div>
)}



            </div>
        </div>
    );
};

export default QuestionsPage;
