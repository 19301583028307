import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebaseConfig';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { GAD7_QUESTIONS, PHQ9_QUESTIONS, GAD2_QUESTIONS, PHQ2_QUESTIONS } from './utils/questions.js';

ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale
);

const ResultsPage = () => {
    const navigate = useNavigate();
    const [gad7Data, setGad7Data] = useState([]);
    const [phq9Data, setPhq9Data] = useState([]);
    const [gad2Data, setGad2Data] = useState([]);
    const [phq2Data, setPhq2Data] = useState([]);
    const userPublicKey = localStorage.getItem('userPublicKey');

    const fetchAnswers = async () => {
        if (!auth.currentUser) {
            navigate('/');
            return;
        }

        const fetchCollectionData = async (collectionName) => {
            const q = query(
                collection(db, 'users', /*auth.currentUser.uid*/userPublicKey, collectionName),
                orderBy('timestamp', 'desc')
            );
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        };

        try {
            const [gad7, phq9, gad2, phq2] = await Promise.all([
                fetchCollectionData('gad7'),
                fetchCollectionData('phq9'),
                fetchCollectionData('gad2'),
                fetchCollectionData('phq2')
            ]);

            setGad7Data(gad7);
            setPhq9Data(phq9);
            setGad2Data(gad2);
            setPhq2Data(phq2);
        } catch (error) {
            console.error("Error fetching answers: ", error);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate('/');
            } else {
                fetchAnswers();
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const processChartData = (answersData, questionsToShow = null) => {
        const chartData = {
            labels: [],
            datasets: []
        };

        if (answersData.length > 0) {
            const questions = questionsToShow || Object.keys(answersData[0].answers);
            chartData.labels = answersData.map(entry => new Date(entry.timestamp.seconds * 1000));

            questions.forEach(question => {
                const dataset = {
                    label: question,
                    data: answersData.map(entry => {
                        const answer = Number(entry.answers[question]);
                        return answer;
                    }),
                    fill: false,
                    borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color for each dataset
                    tension: 0.1
                };
                chartData.datasets.push(dataset);
            });
        }

        return chartData;
    };

    const processTotalScoreChartData = (answersData, questions) => {
        const chartData = {
            labels: [],
            datasets: [
                {
                    label: 'Total Score',
                    data: [],
                    fill: false,
                    borderColor: '#FF5733', // Fixed color for total score chart
                    tension: 0.1
                }
            ]
        };

        if (answersData.length > 0) {
            chartData.labels = answersData.map(entry => new Date(entry.timestamp.seconds * 1000));

            chartData.datasets[0].data = answersData.map(entry => {
                const totalScore = questions.reduce((sum, question) => {
                    return sum + Number(entry.answers[question]);
                }, 0);
                return totalScore;
            });
        }

        return chartData;
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day'
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Score'
                }
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    font: {
                        size: 10
                    }
                }
            }
        }
    };

    const handleCalculateClick = () => {
        navigate('/calculating');
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Results Page</h1>
            <div style={styles.subHeaderContainer}>
                {userPublicKey && <p style={styles.subHeader}>Public Key: {userPublicKey}</p>}
            </div>
            <div style={styles.chartContainer}>
   
                <div style={styles.section}>
                    <h2 style={styles.sectionHeader}>GAD-2 Data</h2>
                    {gad2Data.length > 0 ? (
                        <>
                            <h2 style={styles.sectionHeader}>Total Score Over Time</h2>
                            <Line data={processChartData(gad2Data)} options={chartOptions} />
                       
                            
                        </>
                    ) : <p style={styles.noDataText}>No GAD-2 data available.</p>}
                </div>

                <div style={styles.section}>
                    <h2 style={styles.sectionHeader}>PHQ-2 Data</h2>
                    {phq2Data.length > 0 ? (
                        <>
                           <h2 style={styles.sectionHeader}>Total Score Over Time</h2>
                            <Line data={processChartData(phq2Data)} options={chartOptions} />
                         
                           
                        </>
                    ) : <p style={styles.noDataText}>No PHQ-2 data available.</p>}
                </div>
                <div style={styles.section}>
                    <h2 style={styles.sectionHeader}>GAD-7 Data</h2>
                    {gad7Data.length > 0 ? (
                        <>
                        <h2 style={styles.sectionHeader}>Total Score Over Time</h2>
                            <Line data={processChartData(gad7Data)} options={chartOptions} />
                            
                   
                        </>
                    ) : <p style={styles.noDataText}>No GAD-7 data available.</p>}
                </div>

                <div style={styles.section}>
                    <h2 style={styles.sectionHeader}>PHQ-9 Data</h2>
                    {phq9Data.length > 0 ? (
                        <>
                          <h2 style={styles.sectionHeader}>Total Score Over Time</h2>
                            <Line data={processChartData(phq9Data)} options={chartOptions} />
                          
                          
                        </>
                    ) : <p style={styles.noDataText}>No PHQ-9 data available.</p>}
                </div>

            </div>
            <div style={styles.buttonContainer}>
                <button onClick={() => navigate('/questions')} style={styles.button}>Go Back to Questions Page</button>
                {phq2Data.length >= 10 && gad2Data.length >= 10 && (
                    <button onClick={handleCalculateClick} style={styles.button}>Go to Calculating Page</button>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '20px',
    },
    header: {
        fontSize: '2rem',
        marginBottom: '20px',
    },
    subHeaderContainer: {

        width: '100%',
        display: 'flex', justifyContent: 'center', marginBottom: '20px' 
    },
    subHeader: {
        fontSize: '1.2rem',
    },
    chartContainer: {
        width: '100%',
        maxWidth: '1200px',
    },
    section: {
        marginBottom: '40px',
    },
    sectionHeader: {
        fontSize: '1.5rem',
        marginBottom: '10px',
    },
    noDataText: {
        fontSize: '1rem',
        color: 'grey',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '600px',
    },
    button: {        padding: '10px 20px',
        fontSize: '1rem',
        margin: '10px',
        cursor: 'pointer',
        borderRadius: '5px',
        border: '1px solid #ccc',
        backgroundColor: '#f9f9f9',
        transition: 'background-color 0.3s ease',
    },
};

export default ResultsPage;

       
