// In src/utils/logout.js or a similar path
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('userPublicKey'); // Clear the user's public key
    // Clear any other session-related data here
    navigate('/'); // Navigate to landing page or login page after logout
  };

  return logout;
};
