import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage'; // Import your component
import QuestionsPage from './QuestionsPage';
import ResultsPage from './ResultsPage';
import CalculatingPage from './CalculatingPage';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/questions" element={<QuestionsPage />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/calculating" element={<CalculatingPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
